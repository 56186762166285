import React from 'react';
import Image from '../image';
import { FEATURES_DATA } from "../../constants"

const renderDescription = (item) => {
  return(
    <p key={item} className='section-description'>{item}</p>
  )
}
const ImageWithText = ({type }) => {
  const {image,orientation,title,description} = FEATURES_DATA[type];
  return (
    <section className='row feature-box' id={orientation}>
      <div className='section-title mobile'>{title}</div>
      <div className='col-md-5 image'>
        <Image src={image}/>
      </div>
      <div className='col-md-7 content-wrapper'>
        <div className='section-title desktop'>{title}</div>
        {description.map(renderDescription)}
        {/*<SectionTitle title={title} description={description}/>*/}
      </div>
    </section>
  );
};

export default ImageWithText;
