import React from 'react';
import HowItWorksImage from '!svg-react-loader!../images/vectors/how-it-works.svg';

const HowItWorks = () => {
  return (
    <section className='row how-it-works'>
      <div className='section-title'>How it works</div>
      <HowItWorksImage/>
    </section>
  );
};

export default HowItWorks;
