import React from 'react';
import BannerImage from '!svg-react-loader!../images/vectors/banner.svg';
import DiagnosticsBannerImage from '!svg-react-loader!../images/vectors/diagnostics_banner.svg';
import FormComponent from './Demo/form-component';
import { WEBSITE } from '../constants';

const Banner = ({ onFormSubmit, title, website }) => (
  <div className='row banner-outer' id="home">
    <div className='banner-title mobile'>{title}</div>
    <div className='col-md-6 col-sm-12 banner-content'>
      <div className='banner-title desktop'>{title}</div>
      <div className='form-container'>
        <FormComponent onSubmit={onFormSubmit}/>
      </div>
    </div>
    <div className='col-md-6 col-sm-12 image-banner'>
      {website === WEBSITE ? <DiagnosticsBannerImage/> : <BannerImage/>}
    </div>
  </div>
);

export default Banner;

