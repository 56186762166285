import React from "react";
import FormComponent from "./Demo/form-component"

const FooterDemo = ({onFormSubmit}) => {
  return(
    <section id="demo-form">
      <div className='form-container footer-demo'>
        <FormComponent onSubmit={onFormSubmit}/>
      </div>
    </section>
  )
};

export default FooterDemo;