const getSelectors = (buttonId, loaderId) => {
  return { text: document.querySelector(buttonId), spinner: document.querySelector(loaderId) };
};

export const showSpinner = (buttonId, loaderId) => {
  const { text, spinner } = getSelectors(buttonId, loaderId);
  if (text && spinner) {
    text.disabled = true;
    spinner.style.display = 'block';
  }
};

export const hideSpinner = (buttonId, loaderId) => {
  const { text, spinner } = getSelectors(buttonId, loaderId);
  if (text && spinner) {
    text.disabled = false;
    spinner.style.display = 'none';
  }
};