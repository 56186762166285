import React from 'react';
import SuccessIcon from '!svg-react-loader!../../images/vectors/success_icon.svg';
import CloseIcon from '!svg-react-loader!../../images/vectors/close_icon.svg';
import Image from "../image"

const DemoSuccessForm = ({ onClose }) => {
  return (
    <div className='center' id='demo-success-form'>
      <div className='close-icon' align='right' onClick={onClose}>
        <CloseIcon/>
        {/*<Image src={'close_icon.png'}/>*/}
      </div>
      <SuccessIcon/>
      <div className='demo success-title'>Received</div>
      <div className='demo success-description'>We will get back to you on the details you have specified</div>
    </div>
  );
};

export default DemoSuccessForm;