import React, { Component } from "react"
import ReactModal from 'react-modal';
import DemoInputForm from "./input-form"
import { hideSpinner, showSpinner } from "../../utils"
import DemoSuccessForm from "./success-form"
import DemoErrorForm from "./error-form"
import { DEMO_TYPE, ERROR_FLAG, REQUEST_DEMO_API, SUCCESS_FLAG } from "../../constants"

export default class FormComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      phoneNumber: "",
      city:"",
      submit: "",
      buttonId: ".input-btn .btn",
      loaderId: ".input-btn .loading",
      isOpen:true,
      errorForm:false,
      abbreviation:'Dr'
    }
  }

  componentDidMount() {
    const { buttonId, loaderId } = this.state
    // showSpinner(buttonId, loaderId);
  }

  checkForm = () => {
    const { name, phoneNumber,city } = this.state
    let phoneRegex = /^[6789]\d{9}$/

    if (!phoneNumber.match(phoneRegex) && phoneNumber.length >= 10) {
      this.setState({ errorMsgMbDemo: true })
    } else {
      this.setState({ errorMsgMbDemo: false })
    }
    if (name && name.trim() && city && city.trim() && phoneNumber.match(phoneRegex) && phoneNumber.length === 10) {
      this.setState({
        submit: "submit"
      })
    } else {
      this.setState({
        submit: ""
      })
    }
  }

  onChange = (e) => {
    const { name, value } = e.target
    this.setState({
      [name]: value
    }, this.checkForm)
  }

  handleErrors = (response) => {
    if (!response.ok) {
      throw Error('Error');
    }
    return response;
  };


  onSubmit = () => {
    const { abbreviation,name, phoneNumber,city, buttonId, loaderId } = this.state;
    let fullName = `${abbreviation} ${name}`;
    try {
      const data = {
        name:fullName,
        phoneNumber,
        city,
        flag: DEMO_TYPE,
      };
      showSpinner(buttonId, loaderId);
      fetch(REQUEST_DEMO_API, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }).then(this.handleErrors)
        .then((response) => {
          if (response.status === 200) {
            this.setState({
              name:'',
              phoneNumber:'',
              city:'',
              submit:''
            });
            this.props.onSubmit(SUCCESS_FLAG);
          } else {
            this.props.onSubmit(ERROR_FLAG);
          }
          hideSpinner(buttonId, loaderId);
        })
        .catch((error) => {
          this.props.onSubmit(ERROR_FLAG);
          hideSpinner(buttonId, loaderId);
        });
    } catch (e) {
      this.props.onSubmit(ERROR_FLAG);
      hideSpinner(buttonId, loaderId);
    }
  }

  render() {
    const { name, phoneNumber,city, errorMsgMbDemo, submit, abbreviation} = this.state
    return (
        <DemoInputForm
          name={name}
          city={city}
          phoneNumber={phoneNumber}
          title={"Arrange a Demo"}
          abbreviation={abbreviation}
          submit={submit}
          onChange={this.onChange}
          onSubmit={this.onSubmit}
          errorMsgMbDemo={errorMsgMbDemo}
        />
    )
  }
}
