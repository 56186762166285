import React from "react"

const DemoInputForm = ({ name, phoneNumber,city, title, onChange, onSubmit, submit, onClose, errorMsgMbDemo, abbreviation }) => {
  return (
    <div className='modal-wrap col-sm-12'>
      <div className='modal-title'>
        {title}
      </div>
      <form className='modal-form'>
        <div className='input-wrap'>
          <label>Full Name*</label>
          <div className="input-container">
            <select
              value={abbreviation}
              onChange={onChange}
              name="abbreviation"
              className="form-control"
            >
              <option value="Dr">Dr</option>
              <option value="Mr">Mr</option>
              <option value="Mrs">Mrs</option>
              <option value="Ms">Ms</option>
            </select>
            <input type='text' placeholder='Enter your full name' name='name' value={name} onChange={onChange}/>
          </div>
        </div>
        <div className='input-wrap'>
          <label>Mobile Number*</label>
          <input type='number' placeholder='Enter your mobile number' name='phoneNumber' value={phoneNumber}
                 onChange={onChange} className='flexWidth'/>
          {errorMsgMbDemo ? (<div className='mbErrorMsgDemo'>Please enter a valid mobile number</div>
          ) : null}
        </div>
        <div className='input-wrap'>
          <label>City Name*</label>
          <input type='text' placeholder='Enter city name' name='city' value={city}
                 onChange={onChange} className='flexWidth'/>
        </div>
        <div className='input-btn'>
          <button type='button' className={`btn ${submit}`} disabled={!submit} onClick={onSubmit}>
            Request Demo
            <div className="loader loading"/>
          </button>
          {/*<input type='button' value='Request Demo' className={`btn ${submit}`} disabled={submit?false:true} onClick={onSubmit}/>*/}
        </div>
      </form>
    </div>
  )
}

export default DemoInputForm
